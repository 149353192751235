import { computed, readonly, ref, useContext, useRoute } from '@nuxtjs/composition-api';
// import { addProductsToCartCommand } from './commands/addProductsToCartCommand';
import { applyCouponCommand } from './commands/applyCouponCommand';
import { loadCartCommand } from './commands/loadCartCommand';
import { loadTotalQtyCommand } from './commands/loadTotalQtyCommand';
import { removeCouponCommand } from './commands/removeCouponCommand';
import { removeItemCommand } from './commands/removeItemCommand';
import { updateItemQtyCommand } from './commands/updateItemQtyCommand';
import { useCartStore } from '~/stores';
import { Cart, Product } from '@vue-storefront/magento';
import { CartItemInterface, ProductInterface } from '@vue-storefront/magento-api';
import { ComposableFunctionArgs } from '@vue-storefront/core';
import { UseCartErrors, UseCartInterface } from './useCart';
import cookieNames from '~/enums/cookieNameEnum';
import { useUiNotification } from '~/composables';
import useGtm from '~/composables/useGTM';
import logger from '~/utilities/logger';
import { addItemCommand } from './commands/addItemCommand';
import { handleCartError } from '~/composables/useCart/utils/handleCartError';

/**
 * Allows loading and manipulating cart of the current user.
 *
 * See the {@link UseCartInterface} for a list of methods and values available in this composable.
 */
export function useCart<
  CART extends Cart,
  CART_ITEM extends CartItemInterface,
  PRODUCT extends ProductInterface,
>(): UseCartInterface<CART, CART_ITEM, PRODUCT> {
  const loading = ref<boolean>(false);
  const error = ref<UseCartErrors>({
    addItem: null,
    removeItem: null,
    updateItemQty: null,
    load: null,
    clear: null,
    applyCoupon: null,
    removeCoupon: null,
    loadTotalQty: null,
  });
  const { app } = useContext();
  const context = app.$vsf;
  const cartStore = useCartStore();
  const cart = computed(() => cartStore.cart as CART);
  const apiState = context.$magento.config.state;
  const { trackCartChange } = useGtm();

  /**
   * Assign new cart object
   * @param newCart
   *
   * @return void
   */
  const setCart = (newCart: CART): void => {
    logger.debug('useCart.setCart', newCart);

    const filteredCart = newCart ? { ...newCart, items: newCart.items.filter((item) => item !== null) } : null;

    cartStore.setCart(
      filteredCart ?? {
        id: '',
        is_virtual: false,
        total_quantity: 0,
        shipping_addresses: [],
      },
    );

    updateCookieQuantity();
  };

  /**
   * Check if product is in the cart
   * @param product
   *
   * @return boolean
   */
  const isInCart = (product: PRODUCT): boolean =>
    !!cart.value?.items?.find((cartItem) => cartItem?.product?.uid === product.uid);

  const load = async (
    { customQuery = {}, realCart = false, completeCart = false } = {
      customQuery: { cart: 'cart' },
    },
  ): Promise<void> => {
    logger.debug('useCart.load');
    try {
      loading.value = true;
      const loadedCart = await loadCartCommand.execute(context, {
        customQuery,
        realCart,
        completeCart,
        app,
      });
      cartStore.setCart(loadedCart);
      error.value.load = null;
      updateCookieQuantity();
    } catch (err) {
      error.value.load = err;
      logger.error('useCart/load', err);
    } finally {
      loading.value = false;
    }
  };

  const clear = async ({ customQuery } = { customQuery: { cart: 'cart' } }): Promise<void> => {
    logger.debug('useCart.clear');

    try {
      loading.value = true;
      context.$magento.config.state.removeCartId();
      cartStore.clearCart();
    } catch (err) {
      error.value.clear = err;
      logger.error('useCart/clear', err);
    } finally {
      loading.value = false;
    }
  };

  const loadTotalQty = async (params?: ComposableFunctionArgs<{}>): Promise<void> => {
    logger.debug('useCart.loadTotalQty');

    try {
      loading.value = true;

      // Try to read cart quantity from vsf-cart cookie: "quantity<number>|cartId<string>"
      const cartCookie = app.$cookies.get(cookieNames.cartCookieName);
      const totalQuantity = cartCookie?.indexOf('|')
        ? parseInt(cartCookie?.split('|').shift())
        : await loadTotalQtyCommand.execute(context, params);

      if (cartStore.cart === null) setCart(null);
      cartStore.cart.total_quantity = totalQuantity;

      updateCookieQuantity();
    } catch (err) {
      error.value.loadTotalQty = err;
      logger.error('useCart/loadTotalQty', err);
    } finally {
      loading.value = false;
    }
  };

  const addItem = async ({
    product,
    quantity,
    productConfiguration,
    productCustomOptionsConfiguration,
    customQuery,
  }): Promise<void> => {
    logger.debug('useCart.addItem', { product, quantity });

    try {
      loading.value = true;

      if (!apiState.getCartId()) {
        await load({ realCart: true });
      }

      // @todo: remove when MYJE-4300 is fixed
      await addItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        quantity,
        productConfiguration,
        productCustomOptionsConfiguration,
        customQuery,
      });

      // @todo: enable when MYJE-4300 is fixed
      // await addProductsToCartCommand.execute(context, {
      //   currentCart: cart.value,
      //   product,
      //   quantity,
      //   productConfiguration,
      //   productCustomOptionsConfiguration,
      // });

      error.value.addItem = null;
      updateCookieQuantity();

      const configurable_options = Object.values(productConfiguration || []);
      trackCartChange('addToCart', {
        product,
        sku: product.sku,
        variant:
          product.variants?.find((variant) =>
            variant.attributes.every((attr) => configurable_options.includes(attr.uid)),
          )?.product?.sku || product.sku,
        quantity,
      });

      // Is handled by Exponea now. Will be solved in Nuxt 3
      // sendNotification({
      //   id: Symbol('product_added'),
      //   message: app.i18n.t('Added to your cart!')?.toString(),
      //   type: 'success',
      //   icon: 'check',
      //   persist: false,
      // });
    } catch (err) {
      error.value.addItem = err;
      handleCartError('useCart/addItem', err, app);
    } finally {
      loading.value = false;
    }
  };

  const removeItem = async ({ product }) => {
    logger.debug('useCart.removeItem', { product });

    try {
      loading.value = true;

      await removeItemCommand.execute(context, {
        currentCart: cart.value,
        product,
      });

      error.value.removeItem = null;

      trackCartChange('removeFromCart', {
        product: product.product,
        sku: product.product.sku,
        variant: product.configured_variant?.sku || product.product.sku,
        quantity: product.quantity,
      });
    } catch (err) {
      error.value.removeItem = err;
      logger.error('useCart/removeItem', err);
    } finally {
      loading.value = false;
    }
  };

  const updateItemQty = async ({
    product,
    quantity,
    customQuery = { updateCartItems: 'updateCartItems' },
  }): Promise<void> => {
    logger.debug('useCart.updateItemQty', {
      product,
      quantity,
    });

    if (quantity && quantity > 0) {
      try {
        loading.value = true;
        const updatedCart = await updateItemQtyCommand.execute(context, {
          currentCart: cart.value,
          product,
          quantity,
          customQuery,
        });

        error.value.updateItemQty = null;
        cartStore.setCart(updatedCart);

        const operation = 0 > quantity - product.quantity ? 'removeFromCart' : 'addToCart';

        trackCartChange(operation, {
          product: product.product,
          sku: product.product.sku,
          variant: product.configured_variant?.sku || product.product.sku,
          quantity: Math.abs(quantity - product.quantity),
        });

        updateCookieQuantity();
      } catch (err) {
        error.value.updateItemQty = err;
        logger.error('useCart/updateItemQty', err);
      } finally {
        loading.value = false;
      }
    }
  };

  const handleCoupon = async (couponCode = null, customQuery = null): Promise<void> => {
    const variables = {
      currentCart: cart.value,
      customQuery,
      couponCode,
    };

    const { updatedCart, errors } = couponCode
      ? await applyCouponCommand.execute(context, variables)
      : await removeCouponCommand.execute(context, variables);

    if (errors) {
      throw errors[0];
    }
  };

  const applyCoupon = async ({ couponCode, customQuery }): Promise<void> => {
    logger.debug('useCart.applyCoupon');

    try {
      loading.value = true;
      await handleCoupon(couponCode, customQuery);
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.applyCoupon = err;
      logger.error('useCart/applyCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const removeCoupon = async ({ customQuery }): Promise<void> => {
    logger.debug('useCart.removeCoupon');

    try {
      loading.value = true;
      await handleCoupon(null, customQuery);
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.removeCoupon = err;
      logger.error('useCart/removeCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const canAddToCart = (product: Product, qty = 1) => {
    // @ts-ignore
    if (product?.__typename === 'ConfigurableProduct') {
      return !!product?.configurable_product_options_selection?.variant?.uid;
    }
    const inStock = product?.stock_status || '';
    const stockLeft = product?.only_x_left_in_stock === null ? true : qty <= product?.only_x_left_in_stock;
    return inStock && stockLeft;
  };

  const removeMagentoLocalStorageKeys = () => {
    const keys = ['cart', 'minicart'];
    const storage = JSON.parse(localStorage['mage-cache-storage'] ?? '{}');
    keys.forEach((key) => delete storage[key]);
    localStorage['mage-cache-storage'] = JSON.stringify(storage);
  };

  const updateCookieQuantity = () => {
    const cartId = apiState.getCartId();
    const realCartId = cartId?.indexOf('|') ? cartId.split('|').pop() : cartId;

    if (!realCartId) {
      app.$cookies.remove(cookieNames.cartCookieName);
      return;
    }

    const cartTotalQuantity = cartStore.cart?.total_quantity ?? 0;

    app.$cookies.set(cookieNames.cartCookieName, `${cartTotalQuantity}|${realCartId}`, {
      path: '/',
      sameSite: 'lax',
      maxAge: app.$config.cartCookieTTL,
    });

    removeMagentoLocalStorageKeys();
  };

  return {
    setCart,
    cart,
    loadTotalQty,
    isInCart,
    addItem,
    load,
    removeItem,
    clear,
    updateItemQty,
    applyCoupon,
    removeCoupon,
    canAddToCart,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useCart;
export * from './useCart';
