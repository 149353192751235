import { Logger } from '@vue-storefront/core';
import axios from 'axios';
import _debounce from '~/helpers/debounce';
import { Route } from 'vue-router';
import { RouterData, useRouterApi } from '~/composables/useRouterApi';

export default ({ i18n, app }) => {
  if (JSON.parse((process.env.NUXT_ENV_DISABLE_ROUTER_FETCHER || 'false').toLowerCase())) return;

  /**
   * Fetches data from Nginx router API
   * @param href Absolute or relative href
   */
  const fetchRouterData = async (href: string) => {
    const routerApi = useRouterApi();

    const path = href.replace(process.env.NUXT_ENV_STORE_URL, '');

    // External links (equals NOT starting with /): bail.
    if (path.indexOf('/') > 0) {
      return;
    }

    // Already having a record, bail.
    if (routerApi.getDataByUri(path)) {
      return;
    }

    const { data } = await axios.get<RouterData[]>(`${routerApi.apiUrl}?lookup=${path}`, { timeout: 10000 });

    routerApi.addData(data[0]);
  };

  /**
   * Syncs the vsf cart with magento
   */
  const syncVueWithMagento = async (): Promise<void> => {
    const cartId = app.$cookies.get('vsf-cart')?.split('|')?.pop();

    if (!cartId) return;

    const url = `${app.$config.storeUrl}/vue/cart/sync/token//cart/${cartId}`;

    console.debug('[Magento Sync] URL: ', url);

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`status: ${response.status}`);
      }

      console.debug('[Magento Sync] Done');
    } catch (error) {
      console.error('[Magento Sync] Error:', error);
    }
  };

  const routerApi = useRouterApi();
  routerApi.setBaseUrl(app.$config.storeUrl);

  const delayedFetchRouterData = _debounce(async (path: string) => await fetchRouterData(path), 300); // 300 ms delay to limit requests

  app.router.beforeEach(async (to: Route, from: Route, next: () => void) => {
    // Initial page, bail.
    if (from?.name === null) return next();

    // Bloomreach Experience Manager, bail.
    if (Object.keys(to?.query).includes('token') && Object.keys(to?.query).includes('server-id')) {
      return next();
    }

    Logger.debug('[FetchRouterAPI]', 'Coming from', from);

    await fetchRouterData(to.path);
    const data = routerApi.getDataByUri(to.path);

    if (data) {
      const { route } = data;
      Logger.debug('[FetchRouterAPI]', 'Found route data', route);

      if (route.hostType === 'VSF') {
        Logger.debug('[FetchRouterAPI]', `Found VSF, continue..`);

        if (route.redirect) {
          Logger.debug('[FetchRouterAPI]', `Found VSF redirect, going to /${i18n.locale}/${route.redirect.uri}`);
          document.location.href = `/${i18n.locale}/${route.redirect.uri}`;
          return;
        }

        return next();
      }

      if (route.redirect) {
        Logger.debug(
          '[FetchRouterAPI]',
          'Found redirect',
          JSON.stringify(route),
          `/${i18n.locale}/${route?.redirect?.uri}`,
        );
        // On production, this would be: `https://www.my-jewellery.com/${i18n.locale}/${route?.redirect?.uri}`;
        document.location.href = `${document.location.origin}/${i18n.locale}/${route?.redirect?.uri}`;
        return;
      }

      if (route.hostType === 'MAGENTO' || route.hostType === 'DEFAULT') {
        Logger.debug('[FetchRouterAPI]', `Found MAGENTO, do sync`);

        if (app.$cookies.get('vsf-customer') === undefined) await syncVueWithMagento();

        document.location.href = to.fullPath;
        return;
      }
    }

    next();
  });

  if (process.env.ROUTER_API_FETCH_ON_MOUSEOVER) {
    document.addEventListener(
      'mouseover',
      async function (event) {
        // @ts-ignore - matches/closest transpile just fine.
        if (!event.target.matches('a') && !event.target.closest('a')) return;

        // @ts-ignore - idem dito
        const anchor = event.target.matches('a') ? event.target : event.target.closest('a');
        const url = new URL(anchor.href);
        const path = url?.pathname || '';

        // Skip anchors that open in new tab
        if (anchor?.getAttribute('target') === '_blank') {
          Logger.debug('[FetchRouterAPI]', 'Anchor opening in new tab', path);
          return;
        }

        // External links (equals NOT starting with "/"): bail.
        if (path.indexOf('/') > 0) {
          Logger.debug('[FetchRouterAPI]', 'Path not starting with a slash', path);
          return;
        }

        // Already having a record stored, bail.
        if (routerApi.getDataByUri(path)) {
          Logger.debug('[FetchRouterAPI]', 'Already found a record for', path);
          return;
        }

        Logger.debug('[FetchRouterAPI]', 'Start fetching for', path);

        delayedFetchRouterData(path);
      },
      false,
    );
  }
};
