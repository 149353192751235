import { Context } from '@vue-storefront/core';
import { Cart } from '@vue-storefront/magento';
import customerCartCompleteGql from '~/customQueries/queries/customerCartComplete';
import logger from '~/utilities/logger';

export const loadCartCommand = {
  execute: async (
    context: Context,
    params: { customQuery: object; realCart: boolean; completeCart: boolean; app: any } = {
      customQuery: {},
      realCart: false,
      completeCart: false,
      app: {},
    },
  ) => {
    const apiState = context.$magento.config.state;
    logger.debug('[Magento Storefront]: Loading Cart');
    const customerToken = apiState.getCustomerToken();
    const virtual = !params.realCart;
    const createVirtualCart = () => null as Cart;

    const createRealCart = async (): Promise<string> => {
      logger.debug('[Magento Storefront]: useCart.load.createNewCart');

      apiState.setCartId();

      const { data } = await context.$magento.api.createEmptyCart();
      logger.debug('[Result]:', { data });

      apiState.setCartId(data.createEmptyCart);

      return data.createEmptyCart;
    };

    const getCartData = async (id: string) => {
      logger.debug('[Magento Storefront]: useCart.load.getCartData ID->', id);

      const realId = id.indexOf('|') ? id.split('|').pop() : id;

      const { data, errors } = await context.$magento.api.cart(realId);
      logger.debug('[Result]:', { data });

      if (!data?.cart && errors?.length) {
        throw errors[0];
      }

      data.cart.items = data.cart.items.filter(Boolean);
      return data.cart as Cart;
    };

    const getCart = async (virtualCart: boolean, cartId?: string) => {
      if (!cartId) {
        if (virtualCart) {
          return createVirtualCart();
        }

        // eslint-disable-next-line no-param-reassign
        cartId = await createRealCart();
        apiState.setCartId(cartId);
      }

      return getCartData(cartId);
    };

    // Try to load cart for existing customer, clean customer token if not possible
    if (customerToken) {
      try {
        // If completeCart is requested do custom query
        const { data, errors } = params.completeCart
          ? await context.$magento.api.customQuery({
              query: customerCartCompleteGql,
            })
          : await context.$magento.api.customerCart();

        logger.debug('[Result]:', { data, errors });

        if (!data?.customerCart && errors?.length) {
          throw errors[0];
        }

        apiState.setCartId(data.customerCart.id);
        data.customerCart.items = data.customerCart.items.filter(Boolean);
        return data.customerCart;
      } catch {
        logger.error("[Magento Storefront]: Couldn't load cart, removing customer token");
        apiState.setCustomerToken();
      }
    }

    try {
      // If it's not existing customer check if cart id is set and try to load it
      const cartId = apiState.getCartId();
      return await getCart(virtual, cartId);
    } catch {
      logger.error("[Magento Storefront]: Couldn't load cart, remove cookie");
      apiState.setCartId();
      return await getCart(virtual);
    }
  },
};
