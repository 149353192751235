import { useUiNotification } from '~/composables';
import logger from '~/utilities/logger';

export const handleCartError = (errorLocation: string, errors: Record<string, any>[], app) => {
  const { send: sendNotification } = useUiNotification();

  const errorCode = errors?.[0]?.extensions?.error_code || JSON.stringify(errors);
  const apiState = app.$vsf.$magento.config.state;
  const cartCookie = apiState.getCartId();

  logger.error(errorLocation, { cart_id: cartCookie, errorCode });

  sendNotification({
    id: Symbol('product_add_error'),
    message: app.i18n.t(errorCode)?.toString(),
    type: 'danger',
    icon: 'danger',
    persist: false,
  });

  if (errorCode === 'CART_NOT_FOUND' || errorCode === 'CART_NOT_ACTIVE' || errorCode === 'CART_AUTHORIZATION_FAILED') {
    apiState.setCartId();
  }
};
