// @todo: remove when MYJE-4300 is fixed

import { Context } from '@vue-storefront/core';
import { Cart } from '@vue-storefront/magento';
import {
  CartItemInput,
  AddConfigurableProductsToCartInput,
  AddSimpleProductsToCartInput,
} from '@vue-storefront/magento-api';
import { CustomQuery } from '@vue-storefront/core';
import { useCartStore } from '~/stores';
import addSimpleProductsToCart from '~/customQueries/queries/addSimpleProductsToCart.gql';
import logger from '~/utilities/logger';

/** Params object used to add items to a cart */
interface CartItemInputWithCustomOptions extends CartItemInput {
  entered_options?: any[];
}

export declare type AddProductsToCartInput = {
  cartId: string;
  cartItems: CartItemInputWithCustomOptions[];
};

const addSimpleProduct = async ({ context, cartId, quantity, product, productCustomOptionsConfiguration }) => {
  const simpleCartInput: AddSimpleProductsToCartInput = {
    cart_id: cartId,
    cart_items: [
      {
        data: {
          quantity,
          sku: product.sku,
        },
      },
    ],
  };

  if (productCustomOptionsConfiguration) {
    const customOptions = Object.entries(productCustomOptionsConfiguration).map(([id, value_string]) => ({
      id: parseInt(id),
      value_string: value_string.toString(),
    }));

    simpleCartInput.cart_items[0].customizable_options = customOptions;
  }

  const { data: simpleProduct, errors } = await context.$magento.api.addSimpleProductsToCart(simpleCartInput);

  if (errors?.length) throw errors;

  const cartStore = useCartStore();
  cartStore.setCart(simpleProduct.addSimpleProductsToCart?.cart);

  logger.debug('[Result]:', { data: simpleProduct });

  return simpleProduct.addSimpleProductsToCart?.cart as unknown as Cart;
};

const addConfigurableProduct = async ({
  context,
  cartId,
  quantity,
  product,
  productConfiguration,
  productCustomOptionsConfiguration,
}) => {
  const selectedOptions = Object.values(productConfiguration as object);
  const customOptions = Object.entries(productCustomOptionsConfiguration).map(([id, value_string]) => ({
    id: parseInt(id),
    value_string: value_string.toString(),
  }));

  const selectedVariant = product.variants.find((variant) =>
    selectedOptions.every((option) => variant.attributes.find((attribute) => attribute.uid === option)),
  );

  if (!selectedVariant) return logger.warn('No variant found for product ' + product.sku);

  const configurableCartInput: AddConfigurableProductsToCartInput = {
    cart_id: cartId,
    cart_items: [
      {
        data: {
          quantity,
          sku: selectedVariant.product.sku,
          selected_options: selectedOptions,
        },
        parent_sku: product.sku,
        customizable_options: customOptions,
      },
    ],
  };

  const { data: configurableProduct, errors } = await context.$magento.api.addConfigurableProductsToCart(
    configurableCartInput,
  );

  if (errors?.length) throw errors;

  const cartStore = useCartStore();
  cartStore.setCart(configurableProduct.addConfigurableProductsToCart.cart);

  logger.debug('[Result]:', { data: configurableProduct });

  return configurableProduct.addConfigurableProductsToCart.cart as unknown as Cart;
};

const addBundleProduct = async ({ context, cartId, quantity, product, customQuery }) => {
  const createEnteredOptions = () =>
    // eslint-disable-next-line implicit-arrow-linebreak
    product.bundle_options.map((bundleOption) => ({
      ...bundleOption,
      value: bundleOption.value.toString(),
    }));

  const bundleCartInput: AddProductsToCartInput = {
    cartId,
    cartItems: [
      {
        quantity,
        sku: product.sku,
        entered_options: createEnteredOptions(),
      },
    ],
  };

  const bundleProduct = await context.$magento.api.addProductsToCart(bundleCartInput, customQuery as CustomQuery);

  const cartStore = useCartStore();
  cartStore.setCart(bundleProduct.data.addProductsToCart.cart);

  logger.debug('[Result]:', { data: bundleProduct });

  return bundleProduct.data.addProductsToCart.cart as unknown as Cart;
};

const addDownloadableProduct = async ({ context, cartId, quantity, product, customQuery }) => {
  const downloadableCartInput: AddProductsToCartInput = {
    cartId,
    cartItems: [
      {
        quantity,
        sku: product.sku,
      },
    ],
  };

  const downloadableProduct = await context.$magento.api.addProductsToCart(
    downloadableCartInput,
    customQuery as CustomQuery,
  );

  const cartStore = useCartStore();
  cartStore.setCart(downloadableProduct.data.addProductsToCart.cart);

  logger.debug('[Result DownloadableProduct]:', { data: downloadableProduct });

  return downloadableProduct.data.addProductsToCart.cart as unknown as Cart;
};

const addVirtualProduct = async ({ context, cartId, quantity, product, customQuery }) => {
  const virtualCartInput: AddProductsToCartInput = {
    cartId,
    cartItems: [
      {
        quantity,
        sku: product.sku,
      },
    ],
  };
  const virtualProduct = await context.$magento.api.addProductsToCart(virtualCartInput, customQuery as CustomQuery);

  const cartStore = useCartStore();
  cartStore.setCart(virtualProduct.data.addProductsToCart.cart);

  logger.debug('[Result VirtualProduct]:', { data: virtualProduct });

  return virtualProduct.data.addProductsToCart.cart as unknown as Cart;
};

export const addItemCommand = {
  execute: async (
    context: Context,
    { product, quantity, currentCart, productConfiguration, productCustomOptionsConfiguration, customQuery },
  ) => {
    logger.debug('[Magento]: Add item to cart', {
      product,
      quantity,
      currentCart,
    });

    const apiState = context.$magento.config.state;
    const cartId = apiState.getCartId()?.indexOf('|') ? apiState.getCartId().split('|').pop() : apiState.getCartId();

    if (!product) {
      return;
    }

    switch (product.__typename) {
      case 'SimpleProduct':
        return addSimpleProduct({ context, cartId, quantity, product, productCustomOptionsConfiguration });
      case 'ConfigurableProduct':
        return addConfigurableProduct({
          context,
          cartId,
          quantity,
          product,
          productConfiguration,
          productCustomOptionsConfiguration,
        });
      case 'BundleProduct':
        return addBundleProduct({ context, cartId, quantity, product, customQuery });
      case 'DownloadableProduct':
        return addDownloadableProduct({ context, cartId, quantity, product, customQuery });
      case 'VirtualProduct':
        return addVirtualProduct({ context, cartId, quantity, product, customQuery });
      default:
        // eslint-disable-next-line no-underscore-dangle
        throw new Error(`Product Type ${product.__typename} not supported in add to cart yet`);
    }
  },
};
