export const AppFooter = () => import('../../components/AppFooter.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/AppHeader.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const Countdown = () => import('../../components/Countdown.vue' /* webpackChunkName: "components/countdown" */).then(c => wrapFunctional(c.default || c))
export const DropdownBox = () => import('../../components/DropdownBox.vue' /* webpackChunkName: "components/dropdown-box" */).then(c => wrapFunctional(c.default || c))
export const HTMLContent = () => import('../../components/HTMLContent.vue' /* webpackChunkName: "components/h-t-m-l-content" */).then(c => wrapFunctional(c.default || c))
export const HeaderLogo = () => import('../../components/HeaderLogo.vue' /* webpackChunkName: "components/header-logo" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavigation = () => import('../../components/HeaderNavigation.vue' /* webpackChunkName: "components/header-navigation" */).then(c => wrapFunctional(c.default || c))
export const LazyLoad = () => import('../../components/LazyLoad.vue' /* webpackChunkName: "components/lazy-load" */).then(c => wrapFunctional(c.default || c))
export const LocaleSidebar = () => import('../../components/LocaleSidebar.vue' /* webpackChunkName: "components/locale-sidebar" */).then(c => wrapFunctional(c.default || c))
export const NewsletterModal = () => import('../../components/NewsletterModal.vue' /* webpackChunkName: "components/newsletter-modal" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../components/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const ProductList = () => import('../../components/ProductList.vue' /* webpackChunkName: "components/product-list" */).then(c => wrapFunctional(c.default || c))
export const ProductsCarousel = () => import('../../components/ProductsCarousel.vue' /* webpackChunkName: "components/products-carousel" */).then(c => wrapFunctional(c.default || c))
export const StoreSwitcher = () => import('../../components/StoreSwitcher.vue' /* webpackChunkName: "components/store-switcher" */).then(c => wrapFunctional(c.default || c))
export const TopBar = () => import('../../components/TopBar.vue' /* webpackChunkName: "components/top-bar" */).then(c => wrapFunctional(c.default || c))
export const UserAddressDetails = () => import('../../components/UserAddressDetails.vue' /* webpackChunkName: "components/user-address-details" */).then(c => wrapFunctional(c.default || c))
export const UserBillingAddress = () => import('../../components/UserBillingAddress.vue' /* webpackChunkName: "components/user-billing-address" */).then(c => wrapFunctional(c.default || c))
export const UserShippingAddress = () => import('../../components/UserShippingAddress.vue' /* webpackChunkName: "components/user-shipping-address" */).then(c => wrapFunctional(c.default || c))
export const CartSidebar = () => import('../../components/Cart/CartSidebar.vue' /* webpackChunkName: "components/cart-sidebar" */).then(c => wrapFunctional(c.default || c))
export const CartSidebarItem = () => import('../../components/Cart/CartSidebarItem.vue' /* webpackChunkName: "components/cart-sidebar-item" */).then(c => wrapFunctional(c.default || c))
export const CartVariant = () => import('../../components/Cart/CartVariant.vue' /* webpackChunkName: "components/cart-variant" */).then(c => wrapFunctional(c.default || c))
export const CartCouponCode = () => import('../../components/Cart/CouponCode.vue' /* webpackChunkName: "components/cart-coupon-code" */).then(c => wrapFunctional(c.default || c))
export const CartEmptyCartAlternativeProducts = () => import('../../components/Cart/EmptyCartAlternativeProducts.vue' /* webpackChunkName: "components/cart-empty-cart-alternative-products" */).then(c => wrapFunctional(c.default || c))
export const CartGiftWrapping = () => import('../../components/Cart/GiftWrapping.vue' /* webpackChunkName: "components/cart-gift-wrapping" */).then(c => wrapFunctional(c.default || c))
export const CartQuantitySelector = () => import('../../components/Cart/QuantitySelector.vue' /* webpackChunkName: "components/cart-quantity-selector" */).then(c => wrapFunctional(c.default || c))
export const CartShinyCombination = () => import('../../components/Cart/ShinyCombination.vue' /* webpackChunkName: "components/cart-shiny-combination" */).then(c => wrapFunctional(c.default || c))
export const Category = () => import('../../components/Category/Category.vue' /* webpackChunkName: "components/category" */).then(c => wrapFunctional(c.default || c))
export const CategoryListHorizontal = () => import('../../components/Category/CategoryListHorizontal.vue' /* webpackChunkName: "components/category-list-horizontal" */).then(c => wrapFunctional(c.default || c))
export const CategoryFiltersSortingSidebar = () => import('../../components/Category/FiltersSortingSidebar.vue' /* webpackChunkName: "components/category-filters-sorting-sidebar" */).then(c => wrapFunctional(c.default || c))
export const CategoryPagination = () => import('../../components/Category/Pagination.vue' /* webpackChunkName: "components/category-pagination" */).then(c => wrapFunctional(c.default || c))
export const CategoryPriceRangeSlider = () => import('../../components/Category/PriceRangeSlider.vue' /* webpackChunkName: "components/category-price-range-slider" */).then(c => wrapFunctional(c.default || c))
export const CategoryProductListingCampaign = () => import('../../components/Category/ProductListingCampaign.vue' /* webpackChunkName: "components/category-product-listing-campaign" */).then(c => wrapFunctional(c.default || c))
export const CategoryProductListingUsps = () => import('../../components/Category/ProductListingUsps.vue' /* webpackChunkName: "components/category-product-listing-usps" */).then(c => wrapFunctional(c.default || c))
export const CategoryProductRecommendations = () => import('../../components/Category/ProductRecommendations.vue' /* webpackChunkName: "components/category-product-recommendations" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAdyenPaymentProvider = () => import('../../components/Checkout/AdyenPaymentProvider.vue' /* webpackChunkName: "components/checkout-adyen-payment-provider" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCartPreview = () => import('../../components/Checkout/CartPreview.vue' /* webpackChunkName: "components/checkout-cart-preview" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPickupLocationButton = () => import('../../components/Checkout/PickupLocationButton.vue' /* webpackChunkName: "components/checkout-pickup-location-button" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPickupLocations = () => import('../../components/Checkout/PickupLocations.vue' /* webpackChunkName: "components/checkout-pickup-locations" */).then(c => wrapFunctional(c.default || c))
export const CheckoutUserBillingAddresses = () => import('../../components/Checkout/UserBillingAddresses.vue' /* webpackChunkName: "components/checkout-user-billing-addresses" */).then(c => wrapFunctional(c.default || c))
export const CheckoutUserShipping = () => import('../../components/Checkout/UserShipping.vue' /* webpackChunkName: "components/checkout-user-shipping" */).then(c => wrapFunctional(c.default || c))
export const CheckoutUserShippingAddresses = () => import('../../components/Checkout/UserShippingAddresses.vue' /* webpackChunkName: "components/checkout-user-shipping-addresses" */).then(c => wrapFunctional(c.default || c))
export const CheckoutUserShippingExistingUser = () => import('../../components/Checkout/UserShippingExistingUser.vue' /* webpackChunkName: "components/checkout-user-shipping-existing-user" */).then(c => wrapFunctional(c.default || c))
export const CheckoutUserShippingForm = () => import('../../components/Checkout/UserShippingForm.vue' /* webpackChunkName: "components/checkout-user-shipping-form" */).then(c => wrapFunctional(c.default || c))
export const CheckoutUserShippingNewUser = () => import('../../components/Checkout/UserShippingNewUser.vue' /* webpackChunkName: "components/checkout-user-shipping-new-user" */).then(c => wrapFunctional(c.default || c))
export const CheckoutVsfPaymentProvider = () => import('../../components/Checkout/VsfPaymentProvider.vue' /* webpackChunkName: "components/checkout-vsf-payment-provider" */).then(c => wrapFunctional(c.default || c))
export const CheckoutVsfShippingProvider = () => import('../../components/Checkout/VsfShippingProvider.vue' /* webpackChunkName: "components/checkout-vsf-shipping-provider" */).then(c => wrapFunctional(c.default || c))
export const CurrencySelectorCurrenciesModal = () => import('../../components/CurrencySelector/CurrenciesModal.vue' /* webpackChunkName: "components/currency-selector-currencies-modal" */).then(c => wrapFunctional(c.default || c))
export const GeneralIconSprite = () => import('../../components/General/IconSprite.vue' /* webpackChunkName: "components/general-icon-sprite" */).then(c => wrapFunctional(c.default || c))
export const GeneralSvgImage = () => import('../../components/General/SvgImage.vue' /* webpackChunkName: "components/general-svg-image" */).then(c => wrapFunctional(c.default || c))
export const MyAccountAddressForm = () => import('../../components/MyAccount/AddressForm.vue' /* webpackChunkName: "components/my-account-address-form" */).then(c => wrapFunctional(c.default || c))
export const MyAccountBillingAddressForm = () => import('../../components/MyAccount/BillingAddressForm.vue' /* webpackChunkName: "components/my-account-billing-address-form" */).then(c => wrapFunctional(c.default || c))
export const MyAccountPasswordResetForm = () => import('../../components/MyAccount/PasswordResetForm.vue' /* webpackChunkName: "components/my-account-password-reset-form" */).then(c => wrapFunctional(c.default || c))
export const MyAccountProfileUpdateForm = () => import('../../components/MyAccount/ProfileUpdateForm.vue' /* webpackChunkName: "components/my-account-profile-update-form" */).then(c => wrapFunctional(c.default || c))
export const MyAccountShippingAddressForm = () => import('../../components/MyAccount/ShippingAddressForm.vue' /* webpackChunkName: "components/my-account-shipping-address-form" */).then(c => wrapFunctional(c.default || c))
export const MyAccountTabMenu = () => import('../../components/MyAccount/TabMenu.vue' /* webpackChunkName: "components/my-account-tab-menu" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryAccordion = () => import('../../components/MyJewellery/Accordion.vue' /* webpackChunkName: "components/my-jewellery-accordion" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryAnnouncementHeader = () => import('../../components/MyJewellery/AnnouncementHeader.vue' /* webpackChunkName: "components/my-jewellery-announcement-header" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryArticleFeed = () => import('../../components/MyJewellery/ArticleFeed.vue' /* webpackChunkName: "components/my-jewellery-article-feed" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryBanner = () => import('../../components/MyJewellery/Banner.vue' /* webpackChunkName: "components/my-jewellery-banner" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryBlogPostHeader = () => import('../../components/MyJewellery/BlogPostHeader.vue' /* webpackChunkName: "components/my-jewellery-blog-post-header" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryBreadcrumbs = () => import('../../components/MyJewellery/Breadcrumbs.vue' /* webpackChunkName: "components/my-jewellery-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryBynderAsset = () => import('../../components/MyJewellery/BynderAsset.vue' /* webpackChunkName: "components/my-jewellery-bynder-asset" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryCards = () => import('../../components/MyJewellery/Cards.vue' /* webpackChunkName: "components/my-jewellery-cards" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryCategoryHeader = () => import('../../components/MyJewellery/CategoryHeader.vue' /* webpackChunkName: "components/my-jewellery-category-header" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryCategoryMenu = () => import('../../components/MyJewellery/CategoryMenu.vue' /* webpackChunkName: "components/my-jewellery-category-menu" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryDefaultContent = () => import('../../components/MyJewellery/DefaultContent.vue' /* webpackChunkName: "components/my-jewellery-default-content" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryExponeaForm = () => import('../../components/MyJewellery/ExponeaForm.vue' /* webpackChunkName: "components/my-jewellery-exponea-form" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryFallback = () => import('../../components/MyJewellery/Fallback.vue' /* webpackChunkName: "components/my-jewellery-fallback" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryFaq = () => import('../../components/MyJewellery/Faq.vue' /* webpackChunkName: "components/my-jewellery-faq" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryHeaderNotification = () => import('../../components/MyJewellery/HeaderNotification.vue' /* webpackChunkName: "components/my-jewellery-header-notification" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryIdentifier = () => import('../../components/MyJewellery/Identifier.vue' /* webpackChunkName: "components/my-jewellery-identifier" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryIndividualProductCard = () => import('../../components/MyJewellery/IndividualProductCard.vue' /* webpackChunkName: "components/my-jewellery-individual-product-card" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryInlineContainer = () => import('../../components/MyJewellery/InlineContainer.vue' /* webpackChunkName: "components/my-jewellery-inline-container" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryInlineNotification = () => import('../../components/MyJewellery/InlineNotification.vue' /* webpackChunkName: "components/my-jewellery-inline-notification" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryInstagram = () => import('../../components/MyJewellery/Instagram.vue' /* webpackChunkName: "components/my-jewellery-instagram" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryLeftMenu = () => import('../../components/MyJewellery/LeftMenu.vue' /* webpackChunkName: "components/my-jewellery-left-menu" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryLogoBanner = () => import('../../components/MyJewellery/LogoBanner.vue' /* webpackChunkName: "components/my-jewellery-logo-banner" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryNavMenu = () => import('../../components/MyJewellery/NavMenu.vue' /* webpackChunkName: "components/my-jewellery-nav-menu" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryNestedLeftMenu = () => import('../../components/MyJewellery/NestedLeftMenu.vue' /* webpackChunkName: "components/my-jewellery-nested-left-menu" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryPhotoslurp = () => import('../../components/MyJewellery/Photoslurp.vue' /* webpackChunkName: "components/my-jewellery-photoslurp" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryProductCard = () => import('../../components/MyJewellery/ProductCard.vue' /* webpackChunkName: "components/my-jewellery-product-card" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryProductCardInfo = () => import('../../components/MyJewellery/ProductCardInfo.vue' /* webpackChunkName: "components/my-jewellery-product-card-info" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryProductCardLabels = () => import('../../components/MyJewellery/ProductCardLabels.vue' /* webpackChunkName: "components/my-jewellery-product-card-labels" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryProductCardLoading = () => import('../../components/MyJewellery/ProductCardLoading.vue' /* webpackChunkName: "components/my-jewellery-product-card-loading" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryProductCardRating = () => import('../../components/MyJewellery/ProductCardRating.vue' /* webpackChunkName: "components/my-jewellery-product-card-rating" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryProductCarousel = () => import('../../components/MyJewellery/ProductCarousel.vue' /* webpackChunkName: "components/my-jewellery-product-carousel" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryProductVariantSelectionModal = () => import('../../components/MyJewellery/ProductVariantSelectionModal.vue' /* webpackChunkName: "components/my-jewellery-product-variant-selection-modal" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryRichText = () => import('../../components/MyJewellery/RichText.vue' /* webpackChunkName: "components/my-jewellery-rich-text" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryRow = () => import('../../components/MyJewellery/Row.vue' /* webpackChunkName: "components/my-jewellery-row" */).then(c => wrapFunctional(c.default || c))
export const MyJewellerySaleLabel = () => import('../../components/MyJewellery/SaleLabel.vue' /* webpackChunkName: "components/my-jewellery-sale-label" */).then(c => wrapFunctional(c.default || c))
export const MyJewellerySeoText = () => import('../../components/MyJewellery/SeoText.vue' /* webpackChunkName: "components/my-jewellery-seo-text" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryShopInShop = () => import('../../components/MyJewellery/ShopInShop.vue' /* webpackChunkName: "components/my-jewellery-shop-in-shop" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryStoreGrid = () => import('../../components/MyJewellery/StoreGrid.vue' /* webpackChunkName: "components/my-jewellery-store-grid" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryStoreGridItem = () => import('../../components/MyJewellery/StoreGridItem.vue' /* webpackChunkName: "components/my-jewellery-store-grid-item" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryTikTok = () => import('../../components/MyJewellery/TikTok.vue' /* webpackChunkName: "components/my-jewellery-tik-tok" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryTopHeader = () => import('../../components/MyJewellery/TopHeader.vue' /* webpackChunkName: "components/my-jewellery-top-header" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryVideo = () => import('../../components/MyJewellery/Video.vue' /* webpackChunkName: "components/my-jewellery-video" */).then(c => wrapFunctional(c.default || c))
export const ProductAddToCart = () => import('../../components/Product/AddToCart.vue' /* webpackChunkName: "components/product-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const ProductAddToWishlist = () => import('../../components/Product/AddToWishlist.vue' /* webpackChunkName: "components/product-add-to-wishlist" */).then(c => wrapFunctional(c.default || c))
export const ProductBundleProductSelector = () => import('../../components/Product/BundleProductSelector.vue' /* webpackChunkName: "components/product-bundle-product-selector" */).then(c => wrapFunctional(c.default || c))
export const ProductGroupedProductSelector = () => import('../../components/Product/GroupedProductSelector.vue' /* webpackChunkName: "components/product-grouped-product-selector" */).then(c => wrapFunctional(c.default || c))
export const Product = () => import('../../components/Product/Product.vue' /* webpackChunkName: "components/product" */).then(c => wrapFunctional(c.default || c))
export const ProductActions = () => import('../../components/Product/ProductActions.vue' /* webpackChunkName: "components/product-actions" */).then(c => wrapFunctional(c.default || c))
export const ProductAddReviewForm = () => import('../../components/Product/ProductAddReviewForm.vue' /* webpackChunkName: "components/product-add-review-form" */).then(c => wrapFunctional(c.default || c))
export const ProductBrands = () => import('../../components/Product/ProductBrands.vue' /* webpackChunkName: "components/product-brands" */).then(c => wrapFunctional(c.default || c))
export const ProductComingSoonForm = () => import('../../components/Product/ProductComingSoonForm.vue' /* webpackChunkName: "components/product-coming-soon-form" */).then(c => wrapFunctional(c.default || c))
export const ProductDeliveryText = () => import('../../components/Product/ProductDeliveryText.vue' /* webpackChunkName: "components/product-delivery-text" */).then(c => wrapFunctional(c.default || c))
export const ProductGalleryLightbox = () => import('../../components/Product/ProductGalleryLightbox.vue' /* webpackChunkName: "components/product-gallery-lightbox" */).then(c => wrapFunctional(c.default || c))
export const ProductGalleryLoader = () => import('../../components/Product/ProductGalleryLoader.vue' /* webpackChunkName: "components/product-gallery-loader" */).then(c => wrapFunctional(c.default || c))
export const ProductGallerySlider = () => import('../../components/Product/ProductGallerySlider.vue' /* webpackChunkName: "components/product-gallery-slider" */).then(c => wrapFunctional(c.default || c))
export const ProductInfo = () => import('../../components/Product/ProductInfo.vue' /* webpackChunkName: "components/product-info" */).then(c => wrapFunctional(c.default || c))
export const ProductLabels = () => import('../../components/Product/ProductLabels.vue' /* webpackChunkName: "components/product-labels" */).then(c => wrapFunctional(c.default || c))
export const ProductLightboxSlider = () => import('../../components/Product/ProductLightboxSlider.vue' /* webpackChunkName: "components/product-lightbox-slider" */).then(c => wrapFunctional(c.default || c))
export const ProductPrice = () => import('../../components/Product/ProductPrice.vue' /* webpackChunkName: "components/product-price" */).then(c => wrapFunctional(c.default || c))
export const ProductRating = () => import('../../components/Product/ProductRating.vue' /* webpackChunkName: "components/product-rating" */).then(c => wrapFunctional(c.default || c))
export const ProductRelatedArticle = () => import('../../components/Product/ProductRelatedArticle.vue' /* webpackChunkName: "components/product-related-article" */).then(c => wrapFunctional(c.default || c))
export const ProductRelatedColors = () => import('../../components/Product/ProductRelatedColors.vue' /* webpackChunkName: "components/product-related-colors" */).then(c => wrapFunctional(c.default || c))
export const ProductRelatedPhotoslurp = () => import('../../components/Product/ProductRelatedPhotoslurp.vue' /* webpackChunkName: "components/product-related-photoslurp" */).then(c => wrapFunctional(c.default || c))
export const ProductReviewsSidebar = () => import('../../components/Product/ProductReviewsSidebar.vue' /* webpackChunkName: "components/product-reviews-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ProductSoldOutForm = () => import('../../components/Product/ProductSoldOutForm.vue' /* webpackChunkName: "components/product-sold-out-form" */).then(c => wrapFunctional(c.default || c))
export const ProductSpecialNote = () => import('../../components/Product/ProductSpecialNote.vue' /* webpackChunkName: "components/product-special-note" */).then(c => wrapFunctional(c.default || c))
export const ProductSwiperSlide = () => import('../../components/Product/ProductSwiperSlide.vue' /* webpackChunkName: "components/product-swiper-slide" */).then(c => wrapFunctional(c.default || c))
export const ProductSwiperSlidePicture = () => import('../../components/Product/ProductSwiperSlidePicture.vue' /* webpackChunkName: "components/product-swiper-slide-picture" */).then(c => wrapFunctional(c.default || c))
export const ProductSwiperSlideVideo = () => import('../../components/Product/ProductSwiperSlideVideo.vue' /* webpackChunkName: "components/product-swiper-slide-video" */).then(c => wrapFunctional(c.default || c))
export const ProductSwiperSlider = () => import('../../components/Product/ProductSwiperSlider.vue' /* webpackChunkName: "components/product-swiper-slider" */).then(c => wrapFunctional(c.default || c))
export const ProductTabs = () => import('../../components/Product/ProductTabs.vue' /* webpackChunkName: "components/product-tabs" */).then(c => wrapFunctional(c.default || c))
export const ProductTrustedShopsWidget = () => import('../../components/Product/ProductTrustedShopsWidget.vue' /* webpackChunkName: "components/product-trusted-shops-widget" */).then(c => wrapFunctional(c.default || c))
export const ProductUsps = () => import('../../components/Product/ProductUsps.vue' /* webpackChunkName: "components/product-usps" */).then(c => wrapFunctional(c.default || c))
export const ProductVariantColor = () => import('../../components/Product/ProductVariantColor.vue' /* webpackChunkName: "components/product-variant-color" */).then(c => wrapFunctional(c.default || c))
export const ProductVariantEngraving = () => import('../../components/Product/ProductVariantEngraving.vue' /* webpackChunkName: "components/product-variant-engraving" */).then(c => wrapFunctional(c.default || c))
export const ProductVariantGiftcardPrice = () => import('../../components/Product/ProductVariantGiftcardPrice.vue' /* webpackChunkName: "components/product-variant-giftcard-price" */).then(c => wrapFunctional(c.default || c))
export const ProductVariantMatrix = () => import('../../components/Product/ProductVariantMatrix.vue' /* webpackChunkName: "components/product-variant-matrix" */).then(c => wrapFunctional(c.default || c))
export const ProductVariantSelect = () => import('../../components/Product/ProductVariantSelect.vue' /* webpackChunkName: "components/product-variant-select" */).then(c => wrapFunctional(c.default || c))
export const ProductVariantSize = () => import('../../components/Product/ProductVariantSize.vue' /* webpackChunkName: "components/product-variant-size" */).then(c => wrapFunctional(c.default || c))
export const ProductRatingInput = () => import('../../components/Product/RatingInput.vue' /* webpackChunkName: "components/product-rating-input" */).then(c => wrapFunctional(c.default || c))
export const ProductSizeChartSidebar = () => import('../../components/Product/SizeChartSidebar.vue' /* webpackChunkName: "components/product-size-chart-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ProductsBestsellerProducts = () => import('../../components/Products/BestsellerProducts.vue' /* webpackChunkName: "components/products-bestseller-products" */).then(c => wrapFunctional(c.default || c))
export const ProductsLastViewedProducts = () => import('../../components/Products/LastViewedProducts.vue' /* webpackChunkName: "components/products-last-viewed-products" */).then(c => wrapFunctional(c.default || c))
export const ProductsRecommendedProducts = () => import('../../components/Products/RecommendedProducts.vue' /* webpackChunkName: "components/products-recommended-products" */).then(c => wrapFunctional(c.default || c))
export const ProductsRelatedProducts = () => import('../../components/Products/RelatedProducts.vue' /* webpackChunkName: "components/products-related-products" */).then(c => wrapFunctional(c.default || c))
export const ProductsUpsellProducts = () => import('../../components/Products/UpsellProducts.vue' /* webpackChunkName: "components/products-upsell-products" */).then(c => wrapFunctional(c.default || c))
export const Sidebar = () => import('../../components/Sidebar/Sidebar.vue' /* webpackChunkName: "components/sidebar" */).then(c => wrapFunctional(c.default || c))
export const SidebarCloseButton = () => import('../../components/Sidebar/SidebarCloseButton.vue' /* webpackChunkName: "components/sidebar-close-button" */).then(c => wrapFunctional(c.default || c))
export const SidebarTitle = () => import('../../components/Sidebar/SidebarTitle.vue' /* webpackChunkName: "components/sidebar-title" */).then(c => wrapFunctional(c.default || c))
export const TopNav = () => import('../../components/TopNav/TopNav.vue' /* webpackChunkName: "components/top-nav" */).then(c => wrapFunctional(c.default || c))
export const TopNavCtaItem = () => import('../../components/TopNav/TopNavCtaItem.vue' /* webpackChunkName: "components/top-nav-cta-item" */).then(c => wrapFunctional(c.default || c))
export const TopNavMenu = () => import('../../components/TopNav/TopNavMenu.vue' /* webpackChunkName: "components/top-nav-menu" */).then(c => wrapFunctional(c.default || c))
export const TopNavMenuItem = () => import('../../components/TopNav/TopNavMenuItem.vue' /* webpackChunkName: "components/top-nav-menu-item" */).then(c => wrapFunctional(c.default || c))
export const TopNavNestedMenu = () => import('../../components/TopNav/TopNavNestedMenu.vue' /* webpackChunkName: "components/top-nav-nested-menu" */).then(c => wrapFunctional(c.default || c))
export const TopNavUspItem = () => import('../../components/TopNav/TopNavUspItem.vue' /* webpackChunkName: "components/top-nav-usp-item" */).then(c => wrapFunctional(c.default || c))
export const WishlistItem = () => import('../../components/Wishlist/WishlistItem.vue' /* webpackChunkName: "components/wishlist-item" */).then(c => wrapFunctional(c.default || c))
export const WishlistSidebar = () => import('../../components/Wishlist/WishlistSidebar.vue' /* webpackChunkName: "components/wishlist-sidebar" */).then(c => wrapFunctional(c.default || c))
export const WishlistVariant = () => import('../../components/Wishlist/WishlistVariant.vue' /* webpackChunkName: "components/wishlist-variant" */).then(c => wrapFunctional(c.default || c))
export const CheckoutGettersCartGetters = () => import('../../components/Checkout/getters/cartGetters.js' /* webpackChunkName: "components/checkout-getters-cart-getters" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavigationItem = () => import('../../components/Header/Navigation/HeaderNavigationItem.vue' /* webpackChunkName: "components/header-navigation-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderSearchBarContentSearchResult = () => import('../../components/Header/SearchBar/ContentSearchResult.vue' /* webpackChunkName: "components/header-search-bar-content-search-result" */).then(c => wrapFunctional(c.default || c))
export const HeaderSearchBar = () => import('../../components/Header/SearchBar/SearchBar.vue' /* webpackChunkName: "components/header-search-bar" */).then(c => wrapFunctional(c.default || c))
export const HeaderSearchResults = () => import('../../components/Header/SearchBar/SearchResults.vue' /* webpackChunkName: "components/header-search-results" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryBannerCardItem = () => import('../../components/MyJewellery/Banner/CardItem.vue' /* webpackChunkName: "components/my-jewellery-banner-card-item" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryBannerGridBanner = () => import('../../components/MyJewellery/Banner/GridBanner.vue' /* webpackChunkName: "components/my-jewellery-banner-grid-banner" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryBannerHeroArticle = () => import('../../components/MyJewellery/Banner/HeroArticle.vue' /* webpackChunkName: "components/my-jewellery-banner-hero-article" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryBannerHighlightedArticle = () => import('../../components/MyJewellery/Banner/HighlightedArticle.vue' /* webpackChunkName: "components/my-jewellery-banner-highlighted-article" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryBannerImageTextBanner = () => import('../../components/MyJewellery/Banner/ImageTextBanner.vue' /* webpackChunkName: "components/my-jewellery-banner-image-text-banner" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryBannerImageTextCards = () => import('../../components/MyJewellery/Banner/ImageTextCards.vue' /* webpackChunkName: "components/my-jewellery-banner-image-text-cards" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryBannerLatestArticles = () => import('../../components/MyJewellery/Banner/LatestArticles.vue' /* webpackChunkName: "components/my-jewellery-banner-latest-articles" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryBannerMainBanner = () => import('../../components/MyJewellery/Banner/MainBanner.vue' /* webpackChunkName: "components/my-jewellery-banner-main-banner" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryBannerPromotionBanner = () => import('../../components/MyJewellery/Banner/PromotionBanner.vue' /* webpackChunkName: "components/my-jewellery-banner-promotion-banner" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryBannerRecommendedArticles = () => import('../../components/MyJewellery/Banner/RecommendedArticles.vue' /* webpackChunkName: "components/my-jewellery-banner-recommended-articles" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryFooterCtaBoutique = () => import('../../components/MyJewellery/Footer/CtaBoutique.vue' /* webpackChunkName: "components/my-jewellery-footer-cta-boutique" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryFooterCtaChat = () => import('../../components/MyJewellery/Footer/CtaChat.vue' /* webpackChunkName: "components/my-jewellery-footer-cta-chat" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryFooterCtaReviews = () => import('../../components/MyJewellery/Footer/CtaReviews.vue' /* webpackChunkName: "components/my-jewellery-footer-cta-reviews" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryFooterCtaSubscription = () => import('../../components/MyJewellery/Footer/CtaSubscription.vue' /* webpackChunkName: "components/my-jewellery-footer-cta-subscription" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryFooterFeedbackCompany = () => import('../../components/MyJewellery/Footer/FeedbackCompany.vue' /* webpackChunkName: "components/my-jewellery-footer-feedback-company" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryFooterMenu = () => import('../../components/MyJewellery/Footer/FooterMenu.vue' /* webpackChunkName: "components/my-jewellery-footer-menu" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryFooterPaymentProviders = () => import('../../components/MyJewellery/Footer/FooterPaymentProviders.vue' /* webpackChunkName: "components/my-jewellery-footer-payment-providers" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryFooterTop = () => import('../../components/MyJewellery/Footer/FooterTop.vue' /* webpackChunkName: "components/my-jewellery-footer-top" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryFooterNestedFooterMenu = () => import('../../components/MyJewellery/Footer/NestedFooterMenu.vue' /* webpackChunkName: "components/my-jewellery-footer-nested-footer-menu" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryPageLayoutBlogPost = () => import('../../components/MyJewellery/PageLayout/BlogPost.vue' /* webpackChunkName: "components/my-jewellery-page-layout-blog-post" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryPageLayoutCustomerService = () => import('../../components/MyJewellery/PageLayout/CustomerService.vue' /* webpackChunkName: "components/my-jewellery-page-layout-customer-service" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryPageLayoutOneColumn = () => import('../../components/MyJewellery/PageLayout/OneColumn.vue' /* webpackChunkName: "components/my-jewellery-page-layout-one-column" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryPageLayoutShop = () => import('../../components/MyJewellery/PageLayout/Shop.vue' /* webpackChunkName: "components/my-jewellery-page-layout-shop" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryPageLayoutTwoColumn = () => import('../../components/MyJewellery/PageLayout/TwoColumn.vue' /* webpackChunkName: "components/my-jewellery-page-layout-two-column" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryPiercingStoresGrid = () => import('../../components/MyJewellery/PiercingStores/Grid.vue' /* webpackChunkName: "components/my-jewellery-piercing-stores-grid" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryPiercingStoresGridItem = () => import('../../components/MyJewellery/PiercingStores/GridItem.vue' /* webpackChunkName: "components/my-jewellery-piercing-stores-grid-item" */).then(c => wrapFunctional(c.default || c))
export const MyJewelleryPiercingBooking = () => import('../../components/MyJewellery/PiercingStores/PiercingBooking.vue' /* webpackChunkName: "components/my-jewellery-piercing-booking" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
